/**
 * @me_store-services
 */
import { baseUrlReward, baseUrlCC } from "@/shared/http/api";

export default {
  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  getRewardInfo: () => ({
    method: "GET",
    url: "api/v1/rewards/page-layout",
    baseURL: baseUrlReward,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  rescueReward: () => ({
    method: "POST",
    url: "api/v1/rewards/trade-points",
    baseURL: baseUrlReward,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  rewardsClosed: () => ({
    method: "GET",
    url: "api/v1/rewards/closed",
    baseURL: baseUrlReward,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  rewardsStatuses: () => ({
    method: "GET",
    url: "api/v1/rewards/get-rewards-status",
    baseURL: baseUrlReward,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  rewardsOpened: () => ({
    method: "GET",
    url: "api/v1/rewards/opened",
    baseURL: baseUrlReward,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  allRewards: () => ({
    method: "GET",
    url: "api/v1/rewards",
    baseURL: baseUrlReward,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  filterRewards: () => ({
    method: "GET",
    url: `api/v1/rewards`,
    baseURL: baseUrlReward,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  filterRewardsPaginate: () => ({
    method: "GET",
    url: `api/v1/rewards`,
    baseURL: baseUrlReward,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  rewardMoreItems: () => ({
    method: "GET",
    url: "/api/v1/rewards/page-layout-more/{option_id}",
    baseURL: baseUrlReward,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  giftCards: (options = {}) => ({
    method: "GET",
    url: `/api/v1/giftty/prods/company`,
    params: { ...options },
    baseURL: baseUrlCC,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  giftCardSupplyers: () => ({
    method: "GET",
    url: "/api/v1/giftty/prods/cad/fornecedores",
    baseURL: baseUrlCC,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  giftCardCategories: () => ({
    method: "GET",
    url: "/api/v1/giftty/prods/cad/categorias",
    baseURL: baseUrlCC,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  giftCardDepartments: () => ({
    method: "GET",
    url: "/api/v1/giftty/prods/cad/departamentos",
    baseURL: baseUrlCC,
  }),

  /**
   * @api-version 1
   */
  prePaidCardRescue: () => ({
    method: "POST",
    url: "/api/v1/giftty/prods/cards",
    baseURL: baseUrlCC,
  }),

  /**
   * @api-version 1
   */
  prePaidCardOptions: () => ({
    method: "GET",
    url: "/api/v1/giftty/prods/cards-fornec",
    baseURL: baseUrlCC,
  }),

  /**
   * @api-version 1
   */
  canRescuePrePaidCard: () => ({
    method: "GET",
    url: "/api/v1/rewards/accesso-card-elegible",
    baseURL: baseUrlReward,
  }),
};
