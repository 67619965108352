import { validateLuhn } from "./numbersManipulation";
import i18n from "@/i18n/wrappers/i18n";

const t = i18n.global.t;

export function checkPrePaidCardNumber(value, prefixes) {
  const cleanedValue = value.replace(/\D/g, "");
  const validateCode = Object.values(prefixes).some((prefix) =>
    cleanedValue.startsWith(prefix)
  );

  const validateLength = value.length == 19;
  return (
    (validateLuhn(value) && validateCode && validateLength) ||
    t("validations.numbers_manipulations.invalid_number_card")
  );
}

export const emailMatchValidation = (value, currentValue, field) => {
  const validateEmail = /\S+@\S+\.\S+/;

  return (
    (validateEmail.test(value) && value === currentValue) ||
    t(
      `validations.string_manipulations.${
        value ? "unmatched_field" : "required_field"
      }`,
      { field: field }
    )
  );
};
