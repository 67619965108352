export default {
  finish: "Finalizar",
  see_options: "Ver opções",
  buy: "Comprar",
  buyNow: "Comprar agora",
  share: "Compartilhar",
  moneyTypeLabel: "R$",
  products: "Produtos",
  total: "Total",
  quantity: "Quantidade",
  ethnicity_id: "Etnia",
  quantity_canceled: "Quantidade Cancelada",
  canceled_exchanged_products: "Produtos Cancelados ou Trocados",
  subtotal: "Subtotal",
  discont: "Desconto",
  shipping: "Frete",
  nome: "Nome",
  tax: "Imposto",
  otherCosts: "Outras Taxas",
  welcome: "Bem vinda (o)",
  recuse: "Recusar",
  terms_to_sign: "Novo termo de uso para assinar",
  terms_new_accepted: "Aceito os novos termos",
  acc_bank_code: "Banco",
  acc_agency: "Agência",
  cpf_cnpj: "CPF/CNPJ",
  acc_cc: "Conta",
  acc_dv: "Digito",
  end_cep: "CEP",
  end_rua: "Rua",
  end_cidade: "Cidade",
  end_uf: "UF",
  pf_pis_pasep: "PIS/PASEP",
  pis_pasep: "PIS/PASEP",
  chave_pix: "Chave Pix",
  end_numero: "Número",
  download: "Baixar",
  pf_nascimento: "Data de Nascimento",
  period: "Periodo",
  show: "Visualizar",
  moneyIcon: "R$",
  order: "Pedido",
  send: "Enviar",
  upload_doc: "Fazer Upload",
  cancel: "Cancelar",
  verify: "Verificar",
  continue: "Continuar",
  select: "Selecionar",
  isAgree: "Estou de acordo",
  enter: "Entrar",
  register: "Cadastrar",
  click_here: "Clique Aqui",
  code: "Codigo",
  points: "Pontos",
  description: "Descrição",
  close: "Fechar",
  decline: "Recusar",
  working_late: "Trabalhando até tarde",
  good_evening: "Boa noite",
  good_afternoon: "Boa tarde",
  good_morning: "Bom dia",
  early_bird: "Ual, de madrugada",
  no_show_me_again: "Não exibir novamente",
  accept: "Aceitar",
  copy_link: "Copiar o link",
  external_link: "Link Externo",
  copy_link_description: "Copiar Descrição",
  copied: {
    success: "Copiado!",
    error: "Não foi possivél copiar :(",
  },
  back: "Voltar",
  apply: "Aplicar",
  facebook: "Facebook",
  whatsapp: "Whatsapp",
  twitter: "Twitter",
  show_more: "Ver Mais",
  show_less: "Ver Menos",
  my_store: "Meu espaço",
  save: "Salvar",
  confirm: "confirmar",
  key_pix_type: "Tipo de chave Pix (preencher chave Pix ou Conta)",
  key_pix_content: "Chave Pix (preencher chave Pix ou Conta)",
  checklists: {
    next: "Proxima dica",
    seen_video: "Ver Video",
    no_show_again: "Não mostrar novamente",
    check: "Marcar como concluido",
    seen_latter: "Ver Mais Tarde",
    actions_to_spread: "Ações para divulgar",
  },
  footer: {
    powered_by: "Powered by",
    version: "Versão: { value }",
  },
  show_extract: "Ver Meu Extrato Completo",
  u_are_here: "(Você está aqui)",
  opt_in_terms:
    "Autorizo o envio de mensagens transacionais e novidades para o meu e-mail.",
};
